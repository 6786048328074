import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {useLinkAccountFormControls} from "../../utils/formControls/linkAccountFormControls";
import {useSetWhitelistBankAccountFormControl} from "../../utils/formControls/useSetWhitelistBankAccountFormControl";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import ActionButton from "../button/actionButton";
import GenericError from "../error/genericError";
import SuccessTick from "../success/successTick";
import CircularLoader from "../loading/circularLoader";
import {Typography} from "@material-ui/core";
import TextField from "../input/textField";

const useStyles = makeStyles((theme) => ({
    root: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: '416px',
        height: '706px',
        borderRadius: '20px',
        background: theme.background.dialog
    },
    cardContent: {
        height: '90%',
        margin: '32px !important',
        padding: '0 !important'
    },
    success: {
        height: '95%',
        marginLeft: '24px'
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(4),
        right: theme.spacing(3),
    },
    backdrop: {
        ['@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))']: {
            '-webkit-backdrop-filter': 'blur(2em)',
            backdropFilter: 'blur(2em)',
            backgroundColor: 'rgb(0 0 0 / 33%) !important',
        }
    },
    content: {
        height: '104%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '42px',
        justifyContent: 'space-between',
    },
    upperContent:  {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'stretch',
    },
    heading: {
        marginTop: '24px'
    },
    conversionContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '32px 0px 32px 0px'
    },
    infoCard: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.background.dialog,
    },
    infoCardContent: {
        padding: '12px 24px 12px 24px !important',
        height: '75%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    infoRow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '6px 0px 6px 0px',
    },
    input: {
        margin: '24px 0px 24px 0px !important',
    },
    confirmButton: {
        marginBottom: '42px'
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
    },
    loaderLabel: {
        position: 'absolute',
        top: '60%'
    }
}));

export const SetAutomatcherWhitelistDialog = ({ onClose, onComplete, customerId }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [submitRequested, setSubmitRequested] = useState(false);

    const {
        getValues,
        handleTextInputValue,
        handleFormSubmit,
        formIsValid,
        inputErrors
    } = useSetWhitelistBankAccountFormControl({ customerId });

    const currentValues = getValues();

    const submitCallback = (success) => {
        setLoading(false);
        if (success) {
            setSuccess(true);
            setError(false);
            onComplete();
        } else {
            setError(true)
        }
    }

    useEffect(() => {
        if (loading && submitRequested) {
            handleFormSubmit(submitCallback);
        }
    }, [submitRequested]);

    return (
        <Modal open={true} onClose={() => {onComplete(); onClose();}} BackdropProps={{className:classes.backdrop}}>
            <Fade in={true}>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <ActionButton variant="secondary" size="lg"
                                      className={classes.closeButton}
                                      onClick={() => {onComplete(); onClose();}}
                        >
                            Close
                        </ActionButton>
                        {
                            error ? <div className={classes.resultContainer}>
                                    <GenericError onClick={() => { setError(false); setLoading(true); handleFormSubmit(submitCallback);}}/>
                                </div> :
                                success ? <div className={classes.resultContainer}><SuccessTick trigger={success} tickSize='175px'/></div> :
                                    loading ? <div className={classes.resultContainer}>
                                            <CircularLoader trigger={loading} loaderSize='125px'/>
                                        </div> :
                                        <div className={classes.content}>
                                            <div className={classes.upperContent}>
                                                <Typography variant="h3" className={classes.heading}>Update whitelisted account:</Typography>
                                                <div className={classes.conversionContainer}>
                                                    <TextField
                                                        className={classes.input}
                                                        name='bsb'
                                                        type='text'
                                                        value={currentValues.bsb}
                                                        label="BSB"
                                                        InputLabelProps={{shrink: false, required: true, disableAnimation: true}}
                                                        onChange={handleTextInputValue}
                                                        {...(inputErrors['bsb'] && { error: true, helperText: inputErrors['bsb'] })}
                                                    />
                                                    <TextField
                                                        className={classes.input}
                                                        name='accountNumber'
                                                        type='text'
                                                        value={currentValues.accountNumber}
                                                        label="Account number"
                                                        InputLabelProps={{shrink: false, required: true, disableAnimation: true}}
                                                        onChange={handleTextInputValue}
                                                        {...(inputErrors['accountNumber'] && { error: true, helperText: inputErrors['accountNumber'] })}
                                                    />
                                                </div>
                                            </div>
                                            <ActionButton
                                                variant="primary"
                                                size="lg"
                                                className={classes.confirmButton}
                                                disabled={!formIsValid()}
                                                onClick={() => { setLoading(true); setSubmitRequested(true);}}
                                            >
                                                Add
                                            </ActionButton>
                                        </div>
                        }
                    </CardContent>
                </Card>
            </Fade>
        </Modal>
    )








}