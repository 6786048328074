import { gql } from "@apollo/client";

export const setWhitelistMutation = gql`
  mutation Mutation($input: SetWhitelistSourceBankAccountForAutomatcherInput!, $customerId: String!) {
    setWhitelistSourceBankAccountForAutomatcher_Admin(input: $input, customerId: $customerId) {
      status
      statusDescription
      automatcherBankAccountNumber
      sourceAccount {
        id
        displayAccountName
        accountName
        accountNumber
        bsbNumber
        accountStatus
      }
    }
  }
`