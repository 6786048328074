import {makeStyles} from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import {CircularProgress, IconButton, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {useEffect, useState} from "react";
import GenericError from "../error/genericError";
import CircularLoader from "../loading/circularLoader";
import {useDataProvider} from "react-admin";
import LinkIcon from "../icons/linkIcon";
import RetryIcon from "../icons/retryIcon";
import toast from "react-simple-toasts";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.surface.secondary,
    },
    content: {
        width: '-webkit-fill-available',
        padding: '24px'
    },
    header: {
        paddingBottom: '8px'
    },
    container: {},
    fieldDiv: {
        marginTop: '12px',
    },
    iconButton: {
        marginTop: '12px',
        marginRight: '-12px'
    },
    resetStepUpButton: {
        padding: '0',
        paddingTop: '12px',
        '& > span > svg': {
            width: '26px',
            height: '26px',
        }
    },
    spinner: {
        width: '26px',
        height: '26px',
    },
    detailsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    fieldHeading: {
        marginBottom: '8px'
    }
}));


const kycStateMappings = {
    "APPLICATION_CREATED": "In Progress - Details Submitted",
    "APPLICATION_COMPLETE": "Complete",
    "APPLICATION_REJECTED": "Rejected",
    "APPLICATION_CANCELLED": "Cancelled",
    "APPLICATION_SUSPENDED": "Suspended"
}

const stepUpKycStateMappings = {
    "PENDING": "Pending",
    "APPROVED": "Approved",
    "REJECTED": "Rejected",
    "DISABLED": "Disabled",
    "UNKNOWN": "Not Submitted"
}

const VerificationDetailsCard = ({customerData}) => {
    const customerId = customerData.id
    const stepUpKycStatus = customerData?.stepUpKycStatus

    const classes = useStyles();
    const [data, setData] = useState()
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (data == null) {
            setLoading(true)
            dataProvider.getOne('kyc', {customerId})
                .then(({data}) => {
                    if (data.error) throw data.error;
                    else {
                        setData(data?.result?.getKycApplication);
                        setError(null)
                    }
                }).catch((error) => {
                setError(error);
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [data, customerId, dataProvider])

    return (
        <Card elevation={0} className={classes.root}>
            <CardContent className={classes.content}>
                <Typography className={classes.header} variant="h5">Verification</Typography>
                {
                    error ? <div className={classes.container}><GenericError onClick={() => {
                            setData(null);
                        }}></GenericError></div> :
                        loading ?
                            <div className={classes.container}><CircularLoader trigger={loading} loaderSize='20px'/>
                            </div> :
                            <div className={classes.container}>
                                {
                                    data == null && customerData?.kycStatus === "VERIFIED" ?
                                        <VerificationField title={"Status"} value={"Complete [LEGACY]"}/> :
                                        // not started
                                        data == null ? <VerificationField title={"Status"} value={"Not Started"}/> :
                                            // individual
                                            data.type === "INDIVIDUAL" ? <VerificationDetailsIndividual data={data}
                                                                                                        customerId={customerId}
                                                                                                        stepUpKycStatus={stepUpKycStatus}/> :
                                                // company
                                                data.type === "COMPANY" ? <VerificationDetailsCompany data={data}/> :
                                                    <VerificationDetailsOther data={data}/>
                                }
                            </div>
                }


            </CardContent>
        </Card>
    )
}

export default VerificationDetailsCard

const VerificationDetailsIndividual = ({data, customerId, stepUpKycStatus}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const dataProvider = useDataProvider();

    var applicantId = data?.details?.individual?.applicantId
    // TODO: figure out if we should show check result or if this is sufficient?
    // this might be confusing if the user hasn't completed a check yet
    const url = `https://dashboard.onfido.com/applicant/${applicantId}/details`

    function open() {
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    }

    function resetStepUpStatus() {
        setLoading(true)
        setError(null)
        setSuccess(false)
        dataProvider.create('kyc', {
            customerId: customerId
        }).then(({data}) => {
            if (data.error) throw data.error;
            else {
                setError(null)
                setSuccess(true)
            }
        }).catch((error) => {
            setError(error)
            console.error(error)
        }).finally(() => {
            setLoading(false)
            setSuccess(false)
        })
    }

    useEffect(() => {
        if (error) {
            toast("Failed to reset Step Up status")
        }
    }, [error])

    useEffect(() => {
        if (success) {
            toast("Step Up status reset successfully")
            setSuccess(false)
        }
    }, [success])

    return (
        <div className={classes.container}>
            <div className={classes.detailsContainer}>

                <VerificationField title={"KYC Status"} value={kycStateMappings[data.status]}/>

                {applicantId != undefined && (
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => {
                            open()
                        }}
                    >
                        <LinkIcon/>
                    </IconButton>

                )
                }
            </div>
            <div className={classes.detailsContainer}>

                <VerificationField title={"Step Up Status"}
                                   value={stepUpKycStateMappings[stepUpKycStatus] ?? "Not Submitted"}/>

                {
                    loading ? <CircularProgress className={classes.spinner}/> : <IconButton
                        className={classes.resetStepUpButton}
                        onClick={() => {
                            resetStepUpStatus()
                        }}
                    >
                        <RetryIcon/>
                    </IconButton>
                }
            </div>
        </div>
    )
}

const VerificationDetailsCompany = ({data}) => {
    const classes = useStyles();

    const sumsubStatus = resolveSumsubStatus(data.details?.company?.sumsubApplicationStatus)
    const sumSubApplicantId = data.details?.company?.sumsubApplicantId
    const sumsubApplicantUrl = `https://cockpit.sumsub.com/checkus#/applicant/${sumSubApplicantId}/basicInfo?clientId=stables.money_71800`
    const bridgeStatus = resolveBridgeStatus(data.details?.company?.bridgeApplicationStatus ?? "-")

    function resolveSumsubStatus(status) {
        switch(status) {
            case "not_started":
                return "Not Started"
            case "started":
                return "In progress"
            case "complete":
                return "Complete"
            case "rejected":
                return "Rejected"
            default:
                return "-"
        }
    }

    function resolveBridgeStatus(status) {
        switch(status) {
            case "not_started":
                return "Not Started"
            case "failed_to_create":
                return "Failed to submit"
            case "submitted":
                return "Submitted"
            case "complete":
                return "Complete"
            default:
                return "-"
        }
    }

    function openSumsubApplicantUrl() {
        const win = window.open(sumsubApplicantUrl, '_blank');
        if (win != null) {
            win.focus();
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.detailsContainer}>
                <VerificationField title={"Status"} value={kycStateMappings[data.status] ?? "Incomplete"}/>
            </div>

            <div className={classes.detailsContainer}>
                    <VerificationField title={"Sumsub Status"} value={sumsubStatus}/>
                    {sumSubApplicantId != undefined && (
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => {
                                openSumsubApplicantUrl()
                            }}
                        >
                            <LinkIcon/>
                        </IconButton>
                    )}
                </div>


            <div className={classes.detailsContainer}>
                <VerificationField title={"Bridge Status"} value={bridgeStatus}/>
            </div>
        </div>
    )
}


const VerificationDetailsOther = ({data}) => {
    const classes = useStyles();

    return (
        <div className={classes.detailsContainer}>
            <VerificationField title={"Status"} value={kycStateMappings[data.status]}/>
        </div>
    )
}

const VerificationField = ({title, value}) => {
    const classes = useStyles();

    return (
        <div className={classes.fieldDiv}>
            <Typography className={classes.fieldHeading} variant="subtitle2">{title}</Typography>
            <Typography variant="body1">{value}</Typography>
        </div>
    )
}