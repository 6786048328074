import {useState} from "react";
import {useUpdate} from "react-admin";

export const useSetWhitelistBankAccountFormControl = (initValues) => {

    const [values, setValues] = useState({
        bsb: initValues.bsb || '',
        accountNumber: initValues.accountNumber || '',
        customerId: initValues.customerId,
    })

    const [errors, setErrors] = useState({})
    const [update] = useUpdate('autoMatcherWhitelist')

    const getValues = () => (values)

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        const { bsb, accountNumber } = fieldValues;

        if (bsb)
            temp.bsb = bsb ? '' : "This field is required."

        if (accountNumber)
            temp.accountNumber = accountNumber ? '' : "This field is required."

        setErrors({
            ...temp
        });
    }

    const handleTextInputValue = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
            isUpdated: true
        });
        validate({ [name]: value });
    }

    const handleFormSubmit = async (onComplete) => {
        const { accountName, bsb, accountNumber, customerId } = values;
        update({
            payload: {
                customerId,
                bsbNumber: bsb,
                accountNumber: accountNumber

            }
        }, {
            onSuccess: ({data}) => {
                const isSuccessful = data.error === undefined
                setTimeout(() => {
                    if (data.error) {
                        onComplete(false)
                    } else {
                        onComplete(isSuccessful)
                    }
                }, 1000);
            },
            onFailure: ((e) => console.error(e))
        })
    }

    const formIsValid = (fieldValues = values) => {
        const isValid =
            fieldValues.bsb &&
            fieldValues.accountNumber &&
            Object.values(errors).every((x) => x === '');
        return isValid;
    }

    return {
        getValues,
        handleTextInputValue,
        handleFormSubmit,
        formIsValid,
        inputErrors: errors
    };


}