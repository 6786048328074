import { gql } from "@apollo/client";

export const getKycQuery = gql`
query GetKycApplication($customerId: String!) {
  getKycApplication: getKycApplication_Admin(customerId: $customerId) {
      id
      type
      status
      details {
          individual {
              firstName
              lastName
              applicantId
          }
          company {
              id
              typeOfBusiness
              reasonForUse
              sumsubApplicantId
              sumsubApplicationStatus
              bridgeApplicationStatus
          }
      }
    }
  }
`