import {client} from "./graphql/client";
import {setWhitelistMutation} from "./graphql/whitelist/mutations";
import {formatApolloError} from "./graphql/error";

export const autoMatcherWhitelistDataProvider = {
    update: async(resource, params) => {
        try {
            console.log('params', params)
            const result = await client.mutate({
                mutation: setWhitelistMutation,
                variables: {
                    customerId: params.customerId,
                    input: {
                        accountNumber: params.accountNumber,
                        bsbNumber: params.bsbNumber,
                    }
                }
            })
            await client.clearStore(); // forces cache reset for new queries

            const response = result.data

            return {
                data: {
                    id: params.customerId,
                    result: response
                }
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: params.customerId,
                    error: formatApolloError(e)
                }
            })
        }
    }
}