import { TRANSACTION_TYPES, PAYMENT_METHOD_TYPES } from "../../types";
import { createPayIdReceivablesMutation, linkBankAccountMutation } from "./paymentMethod/mutations";
import {
    cancelPendingDepositMutation,
    cancelSendFiatMutation,
    createDepositMutation,
    createPendingDepositMutation,
    createPendingWithdrawalMutation,
    createSendFiatMutation,
    createWithdrawalMutation,
    refundPendingDepositMutation,
    retrySendFiatMutation,
    redriveAllTransactionsMutation
} from "./transaction/mutations"

export const mapTransactionMutation = (transactionType) => {
    switch (transactionType) {
        case TRANSACTION_TYPES.PENDING_CREDIT:
            return createPendingDepositMutation;
        case TRANSACTION_TYPES.CREDIT:
            return createDepositMutation;
        case TRANSACTION_TYPES.PENDING_DEBIT:
            return createPendingWithdrawalMutation;
        case TRANSACTION_TYPES.DEBIT:
            return createWithdrawalMutation;
        case TRANSACTION_TYPES.SEND_FIAT:
            return createSendFiatMutation;
        case TRANSACTION_TYPES.CANCEL_SEND_FIAT:
            return cancelSendFiatMutation;
        case TRANSACTION_TYPES.RETRY_SEND_FIAT:
            return retrySendFiatMutation;
        case TRANSACTION_TYPES.CANCEL_PENDING_CREDIT:
            return cancelPendingDepositMutation
        case TRANSACTION_TYPES.REFUND_PENDING_CREDIT:
            return refundPendingDepositMutation
        case TRANSACTION_TYPES.REDRIVE_ALL_TRANSACTIONS:
            return redriveAllTransactionsMutation
        default:
            return null;
    }
}


export const mapPaymentMethodMutation = (paymentMethodType) => {
    switch (paymentMethodType) {
        case PAYMENT_METHOD_TYPES.PAY_ID_RECEIVABLES:
            return createPayIdReceivablesMutation;
        case PAYMENT_METHOD_TYPES.PAY_ID_PAYABLES:
        case PAYMENT_METHOD_TYPES.BANK_ACCOUNT_PAYABLES:
            return linkBankAccountMutation;
        case PAYMENT_METHOD_TYPES.BANK_ACCOUNT_RECEIVABLES: // this case is ignored as automatcher is always created during customer creation
        default:
            return null;
    }
}
