/* eslint-disable import/no-anonymous-default-export */
import { yieldDataProvider } from "./yieldDataProvider";
import { customerDataProvider } from "./customerDataProvider";

import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    GET_MANY,
    GET_MANY_REFERENCE,
} from 'react-admin'
import { activitiesDataProvider } from "./activitiesDataProvider";
import { transactionDataProvider } from "./transactionDataProvider";
import { paymentMethodDataProvider } from "./paymentMethodDataProvider";
import { originationDataProvider } from "./originationDataProvider";
import { kycDataProvider } from "./kycDataProvider";
import { addressDataProvider } from "./addressProvider";
import { notesDataProvider } from "./notesDataProvider";
import { orderDataProvider } from "./orderDataProvider";
import { globalActivitiesDataProvider } from "./globalActivitiesDataProvider";
import { enableCardDataProvider } from "./cardEnableDataProvider";
import { autoMatcherWhitelistDataProvider} from "./autoMatcherWhitelistDataProvider";

const dataProviders = [
    {
        dataProvider: customerDataProvider,
        resources: 'customers'
    },
    {
        dataProvider: yieldDataProvider,
        resources: 'yield'
    },
    {
        dataProvider: transactionDataProvider,
        resources: 'transactions'
    },
    {
        dataProvider: activitiesDataProvider,
        resources: 'activities'
    },
    {
        dataProvider: globalActivitiesDataProvider,
        resources: 'globalActivities'
    },
    {
        dataProvider: paymentMethodDataProvider,
        resources: 'paymentMethods'
    },
    {
        dataProvider: originationDataProvider,
        resources: 'origination'
    },
    {
        dataProvider: kycDataProvider,
        resources: 'kyc'
    },
    {
        dataProvider: addressDataProvider,
        resources: 'address'
    },
    {
        dataProvider: notesDataProvider,
        resources: 'notes'
    },
    {
        dataProvider: orderDataProvider,
        resources: 'orders'
    },
    {
        dataProvider: enableCardDataProvider,
        resources: 'enableCards'
    },
    {
        dataProvider: autoMatcherWhitelistDataProvider,
        resources: 'autoMatcherWhitelist'
    }
]

export default (type, resource, params) => {
    const dataProviderMapping = dataProviders.find((dp) =>
        dp.resources.includes(resource));

    const mappingType = {
        [GET_LIST]: 'getList',
        [GET_ONE]: 'getOne',
        [GET_MANY]: 'getMany',
        [GET_MANY_REFERENCE]: 'getManyReference',
        [CREATE]: 'create',
        [UPDATE]: 'update',
        [UPDATE_MANY]: 'updateMany',
        [DELETE]: 'delete',
    };

    return dataProviderMapping.dataProvider[mappingType[type]](resource, params)
}
