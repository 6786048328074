import { gql } from "@apollo/client";

export const createPendingDepositMutation = gql`
  mutation CreatePendingDeposit($input: CreatePendingTransactionInput!) {
    createPendingDepositTransaction: createPendingDepositTransaction_Admin(input: $input) {
      id,
      timestamp,
      amount,
      type,
      category,
      accountId,
      reference,
      currency,
      description,
      orderId,
    }
  }
`


export const createDepositMutation = gql`
  mutation createDeposit($input: CreateTransactionInput!) {
    createDepositTransaction: createDepositTransaction_Admin(input: $input) {
      id,
      timestamp,
      amount,
      type,
      category,
      accountId,
      reference,
      currency,
      description,
      orderId,
    }
  }
`


export const createPendingWithdrawalMutation = gql`
  mutation CreatePendingWithdrawal($input: CreatePendingTransactionInput!) {
    createPendingWithdrawalTransaction: createPendingWithdrawalTransaction_Admin(input: $input) {
      id,
      timestamp,
      amount,
      type,
      category,
      accountId,
      reference,
      currency,
      description,
      orderId,
    }
  }
`


export const createWithdrawalMutation = gql`
  mutation createWithdrawal($input: CreateTransactionInput!) {
    createWithdrawalTransaction: createWithdrawalTransaction_Admin(input: $input) {
      id,
      timestamp,
      amount,
      type,
      category,
      accountId,
      reference,
      currency,
      description,
      orderId,
    }
  }
`

export const createSendFiatMutation = gql`
  mutation createSendFiat($input: CreateTransactionInput!) {
    createSendFiatTransaction: createSendFiatTransaction_Admin(input: $input) {
      id,
      timestamp,
      amount,
      type,
      category,
      accountId,
      reference,
      currency,
      description,
      orderId,
    }
  }
`

export const cancelSendFiatMutation = gql`
    mutation RequestSendFiatCancel_Admin($input: SendFiatRequestCancelInput!) {
        requestSendFiatCancel_Admin(input: $input) {
            id
        }
    }
`

export const retrySendFiatMutation = gql`
    mutation RequestSendFiatRetry_Admin($input: SendFiatRequestRetryInput!) {
        requestSendFiatRetry_Admin(input: $input) {
            id
        }
    }
`

export const refundPendingDepositMutation = gql`
    mutation RefundPendingDepositTransaction_Admin($input: RefundPendingDepositTransactionInput!) {
        refundPendingDepositTransaction_Admin(input: $input) {
            id
        }
    }
`

export const cancelPendingDepositMutation = gql`
  mutation CancelPendingDepositTransaction_Admin($input: CancelPendingDepositTransactionInput!) {
    cancelPendingDepositTransaction_Admin(input: $input) {
      id
    }
  }
`

export const redriveAllTransactionsMutation = gql`
  mutation RedriveAllTransactions_Admin {
    redriveAllTransactions_Admin {
      id
      success
    }
  }
`
