import {enableCardForCustomerMutation} from "./graphql/card/mutations";
import {formatApolloError} from "./graphql/error";
import {client} from "./graphql/client";
import {getCardIsEnabledQuery} from "./graphql/card/query";
import {setWhitelistMutation} from "./graphql/whitelist/mutations";

export const enableCardDataProvider = {
    getOne: async(resource, params) => {
        try {
            const customerId = params.id
            const result = await client.query({
                query: getCardIsEnabledQuery,
                variables: {customerId}
            });

            const customer = result.data.customer;
            return {
                data: customer
            }
        } catch (e) {
            return Promise.reject({
                data: {
                    error: formatApolloError(e)
                }
            });
        }
    },
    create: async(resource, params) => {
        try {
            const result = await client.mutate({
                mutation: enableCardForCustomerMutation,
                variables: {
                    input: params
                }
            })
            await client.clearStore(); // forces cache reset for new queries

            const response = result.data

            return {
                data: {
                    id: params.customerId,
                    result: response
                }
            }
        } catch (e) {
            return Promise.resolve({
                data: {
                    id: params.customerId,
                    error: formatApolloError(e)
                }
            })
        }
    },
}